import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "We're back on 10th Sept!",
  "date": "2022-08-03",
  "author": "Andy B"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`CoderDojo is returning to Bishops Cleeve Library!`}</p>
    <p>{`The free coding club for kids ages 7-17 is back.  We will be learning the basics of coding with Scratch, as well as helping the more advanced kids move on to making websites/apps or even robotics.`}</p>
    <p>{`Our first session will be on Saturday 10th Sept, 1-3pm.  Book your space now:
`}<a parentName="p" {...{
        "href": "https://zen.coderdojo.com/dojos/gb/cheltenham/bishops-cleeve-bishops-cleeve-library"
      }}>{`https://zen.coderdojo.com/dojos/gb/cheltenham/bishops-cleeve-bishops-cleeve-library`}</a></p>
    <p>{`We can't wait to see you there and get started on some awesome projects!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      